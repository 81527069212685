<template>
  <div class="footer">
    <img :src="site.logo_sl" class="footer-logo" />
    <div class="footer-nav">
      <div v-for="(item, index) in list" :key="index" class="footer-nav-l">
        <div class="footer-nav-l-t">
          {{ $t(item.name) }}
          <div class="footer-nav-l-line"></div>
        </div>
        <div class="footer-nav-l-b">
          <div v-for="child in item.children" :key="child" @click="navToPage(child)">{{ $t(child) }}</div>
        </div>
      </div>
    </div>
    <div class="footer-info">
      <div class="copyright">
        {{ site.copyright }}
        <NuxtLink class="beian" to="https://beian.miit.gov.cn/" target="_blank"> {{ site.beian }}</NuxtLink>

      </div>
      <div class="icons">
        <NuxtLink v-for="(item, index) in iconList" :key="index" target="_blank" :to="item.link_url">
          <img :src="item.img_sl" :alt="item.title" />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useSiteStore } from '~/stores/app'
import { routerPath } from '@/utils/constant'
const store = useSiteStore()
let { iconList, site } = storeToRefs(store)
const list = [
  { name: '课程产品和服务', children: ['思科认证', '华为认证', '深信服认证', '锐捷认证', '奇安信认证'] },
  { name: '最新资讯', children: ['行业资讯', '认证信息', '招聘需求'] },
  { name: '合作共赢', children: ['渠道合作', '生态合作'] },
  { name: '关于我们', children: ['公司介绍', '团队师资', '合资企业'] }
]
const navToPage = (title) =>
  navigateTo({
    path: routerPath[title]
  })
</script>
<style lang="scss">
.footer {
  background-color: #f2f3f5;
  padding: 0 136px;
  padding-top: 1px;
  .footer-logo {
    margin-top: 80px;
    width: 192px;
    height: 30px;
  }
  .footer-nav {
    display: flex;
    width: 100%;
    margin-top: 80px;
    .footer-nav-l {
      display: flex;
      flex-direction: column;
      margin-right: 160px;
      .footer-nav-l-t {
        width: 120%;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .footer-nav-l-b {
        div {
          width: 120%;
          cursor: pointer;
          margin-top: 26px;
          color: #6e7378;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .footer-info {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 40px;
    .copyright {
      .beian {
        margin-left: 10px;
      }
      font-size: 14px;
      color: #000000;
    }
    .icons {
      display: flex;
      img {
        margin-right: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    width: 100vw;
    padding: 1px 40rpx;
    .footer-logo {
      margin-top: 120rpx;
      width: 384rpx;
      height: 60rpx;
    }
    .footer-nav {
      flex-direction: column;
      margin-top: 0px;
      .footer-nav-l {
        margin-right: 0;
        width: 100%;
        .footer-nav-l-t {
          position: relative;
          margin-top: 80rpx;
          font-size: 32rpx;
          margin-bottom: 0px;
          width: 100%;
        }
        .footer-nav-l-line {
          position: absolute;
          right: 0;
          top: 30rpx;
          width: 24rpx;
          height: 4rpx;
          background-color: #000000;
        }
        .footer-nav-l-b {
          div {
            width: 100%;
            margin-bottom: 0px;
            margin-top: 40rpx;
            font-size: 28rpx;
          }
        }
      }
    }
    .footer-info {
      padding-top: 90rpx;
      padding-bottom: 100rpx;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .copyright {
        position: absolute;
        top: 140rpx;
        font-size: 24rpx;
        .beian {
          display: block;
        }
      }
      .icons {
        img {
          width: 44rpx;
          height: 44rpx;
        }
      }
    }
  }
}
</style>
