<template>
  <div class="header">
    <!-- 顶部图标，中英文切换 -->
    <div class="link">
      <div class="icons">
        <NuxtLink v-for="(item, index) in iconList" :key="index" target="_blank" :to="item.link_url">
          <img :src="item.img_sl" :alt="item.title" />
        </NuxtLink>
      </div>
      <UDropdown :items="langs" :popper="{ arrow: true, placement: 'bottom-end', strategy: 'absolute' }">
        <div class="lang">
          <img :src="locale === 'cn' ? '/img/icon-china.png' : '/img/icon-english.png'" alt="" />
          <span>{{ locale === 'cn' ? '中文 ' : 'English' }}</span>
          <img src="/img/icon-arrow.png" alt="" />
        </div>
      </UDropdown>
    </div>

    <div v-if="navState !== 2" class="navbar">
      <div class="navbar-logo">
        <NuxtLink to="/">
          <img :src="site.logo_sl" />
        </NuxtLink>
      </div>
      <ul class="navbar-box">
        <li>
          <NuxtLink activeClass="primary-color" to="/">{{ $t('首页') }}</NuxtLink>
        </li>
        <li>
          <a :class="{ 'primary-color': hasRouteActive('/courses') }" @click="toggleDrawerNav">{{ $t('课程产品和服务') }}</a>
        </li>
        <li v-for="(item, index) in navList" :key="index">
          <a :class="{ 'primary-color': hasRouteActive(item.path) }">{{ $t(item.name) }}</a>
          <div v-if="!showSearch" class="drawer">
            <span @click="navToPage(child)" v-for="(child, index) in item.children" :key="index">{{ $t(child) }}</span>
          </div>
        </li>
      </ul>

      <div class="navbar-search">
        <span @click="onSearch(true)" to="/search"> {{ $t('搜索') }}</span>
        <span class="navbar-search-icon" @click="onSearch(true)"> <img class="icon" src="/img/nav-search.png" /></span>

        <div class="menu-wrapper" @click="navState = navState === 0 ? 1 : 0">
          <div :class="navState !== 0 ? 'animate' : ''" class="hamburger-menu"></div>
        </div>
      </div>
    </div>
    <!-- 课程导航 -->
    <div v-if="showDrawerNav" class="drawer-nav">
      <div class="drawer-nav-l">
        <div :class="courseType === item ? 'active' : ''" v-for="(item, index) in courseNavList" :key="index" @click="courseType = item">{{ courseNavData[item] && courseNavData[item].title_lm }}</div>
      </div>
      <div v-if="courseNavData" class="drawer-nav-r">
        <div v-for="(item, index) in courseNavData[courseType].list" :key="index" class="drawer-nav-r-i">
          <img :src="item.img_sl" />
          <span>{{ item.title }}</span>
        </div>
        <NuxtLink target="_blank" @click="showDrawerNav = false" :to="'/courses/' + courseType" class="btn">{{ $t('查看更多') }}</NuxtLink>
        <div class="close" @click="toggleDrawerNav">
          <img src="/img/icon-close.png" />
        </div>
      </div>
    </div>
    <!-- 搜索弹窗 -->
    <div v-if="showSearch" class="drawer-nav">
      <div class="close" @click="onSearch(false)">
        <img src="/img/icon-close.png" />
      </div>
      <div class="drawer-search">
        <input autofocus v-model="keyword" class="search-input" />
        <NuxtLink :to="'/search?keyword=' + keyword" class="search-bar-btn">
          <img src="/img/icon-white-search.png" />
        </NuxtLink>
      </div>
    </div>
    <!-- 手机端导航弹窗 -->
    <div v-if="navState !== 0" class="drawer-mobile">
      <div class="drawer-top">
        <img @click="navState = 1" src="/img/icon-arrow-left.png" alt="" />
        <span>{{ $t(mobileNavTitle) }}</span>
        <img @click="navState = 0" class="close" src="/img/icon-close.png" alt="" />
      </div>
      <div class="drawer-list">
        <div v-for="(item, index) in mobileList" class="drawer-list-i" @click="onClickMobileNav(item)">
          {{ $t(item) }}
          <i class="i-heroicons-chevron-right-16-solid"></i>
        </div>
      </div>
      <div class="drawer-footer">
        <div class="icons">
          <NuxtLink v-for="(item, index) in iconList" :key="index" target="_blank" :to="item.link_url">
            <img :src="item.img_sl" :alt="item.title" />
          </NuxtLink>
        </div>
        <UDropdown :items="langs" :popper="{ arrow: true }">
          <div class="lang">
            <img :src="locale === 'cn' ? '/img/icon-china.png' : '/img/icon-english.png'" alt="" />
            <span>{{ locale === 'cn' ? '中文 ' : 'English' }}</span>
            <img src="/img/icon-arrow.png" alt="" />
          </div>
        </UDropdown>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const store = useSiteStore()
let { iconList, site } = storeToRefs(store)
const { locale, t } = useI18n()
const showDrawerNav = ref(false)
const showSearch = ref(false)
const navState = ref(0) // 0 默认状态 1 一级菜单 2 二级菜单
// pc端显示数据
const navList = [
  { name: '最新资讯', path: '/news', children: ['行业资讯', '认证信息', '招聘需求'] },
  { name: '合作共赢', path: '/cooperate', children: ['渠道合作', '生态合作'] },
  { name: '关于我们', path: '/about', children: ['公司介绍', '团队师资', '合资企业'] }
]

const mobileNavTitle = ref('')
/* 移动端 */
const mobileData: any = [{ name: '首页', path: '/' }, { name: '课程产品和服务', path: '/courses', children: ['思科认证', '华为认证', '深信服认证', '锐捷认证', '奇安信认证'] }, ...navList]
const mobileList = computed(() => {
  if (navState.value === 1) {
    return mobileData.map((item) => item.name)
  }
  if (navState.value === 2) {
    return mobileData.find((item) => item.name === mobileNavTitle.value)?.children
  }
})
const onClickMobileNav = (title) => {
  if (title === t('首页') || title === '首页') {
    navState.value = 0
    return navToPage(title)
  }

  if (navState.value === 1) {
    mobileNavTitle.value = title
    navState.value = 2
  } else {
    navToPage(title)
    navState.value = 0
  }
}
const route = useRoute()
const toggleDrawerNav = () => {
  showDrawerNav.value = !showDrawerNav.value
  showSearch.value = false
}
const onSearch = (status) => {
  showSearch.value = status
  showDrawerNav.value = false
}
/* 语言列表 */
const langs = computed(() => {
  return [
    [
      {
        label: '中文',
        avatar: {
          src: '/img/icon-china.png'
        },
        value: 'cn',
        click: () => {
          locale.value = 'cn'
          localStorage.setItem('locale', 'cn')
          location.reload()
        }
      }
    ],
    [
      {
        label: 'English',
        avatar: {
          src: '/img/icon-english.png'
        },
        value: 'en',
        click: () => {
          locale.value = 'en'
          localStorage.setItem('locale', 'en')
          location.reload()
        }
      }
    ]
  ]
})
/* 1思科 2华为 3深信服 4锐捷 5奇安信 */
const courseNavList = ['cisco', 'huawei', 'sangfor', 'ruijie', 'qiAnXin']
const courseType = ref('cisco')
const keyword = ref('')
const courseNavData = await getCourseNav()
const hasRouteActive = (path) => route.path.includes(path)
const navToPage = (title) =>
  navigateTo({
    path: routerPath[title]
  })
</script>
<style lang="scss">
/* 顶部图标和语言切换 */
.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 130px;
  height: 42px;
  background: #eff0f2;
  .icons {
    display: flex;

    img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }
  .lang {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    img {
      width: 26px;
      height: auto;
    }
    span {
      margin: 0 5px;
    }
  }
}
/* 导航栏 */
.navbar {
  position: relative;
  height: 77px;
  font-size: 16px;
  display: flex;
  padding-bottom: 8px;
  background-color: #fff;
  align-items: center;
  color: #161d25;
  padding: 0 130px 9px;
  // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #eff0f2;
  z-index: 11;
  .navbar-logo {
    margin-right: 25px;
    width: 130px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .navbar-box {
    display: flex;

    > li {
      display: flex;
      align-items: center;
      height: 77px;
      padding: 0 35px;
      position: relative;
      font-size: 18px;
      a {
        cursor: pointer;
      }
      .drawer {
        padding: 30px 0 10px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 71px;
        left: 0;
        z-index: 11;
        width: 100%;
        opacity: 0;
        display: none;
        background-color: #fff;
        border-top: #05b586 solid 4px;
        span {
          // line-height: 1;
          cursor: pointer;
          margin-bottom: 18px;
          text-align: center;
        }
        span:hover {
          color: #05b586;
        }
      }
    }

    li:hover {
      color: #05b586;
      .drawer {
        display: flex;
        opacity: 1;
        color: #000;
      }
    }
  }

  .navbar-search {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-size: 18px;

    .icon {
      height: 22px;
      width: 22px;
      margin-left: 40px;
    }
  }
}

.drawer-nav {
  background-color: #fff;
  position: absolute;
  top: 118px;
  width: 1600px;
  z-index: 10;
  display: flex;
  font-size: 16px;
  .drawer-nav-l {
    width: 379px;
    height: 337px;
    background: #f9f9f9;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .active {
      color: #05b586;
    }
    div {
      cursor: pointer;
    }
  }
  .drawer-nav-r {
    flex: 1;
    display: flex;
    padding-top: 50px;
    padding-left: 105px;
    .drawer-nav-r-i {
      text-align: center;

      margin-right: 30px;
      img {
        width: 207px;
        height: 147px;
        border-radius: 12px 12px 12px 12px;
        margin-bottom: 10px;
      }
      span {
        text-align: center;
      }
    }
    .btn {
      position: absolute;
      width: 148px;
      height: 34px;
      bottom: 40px;
      left: 860px;
      background: #05b586;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
      text-align: center;
      line-height: 34px;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    right: 130px;
    top: 15px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }

  .drawer-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    height: 68px;
    background: #f1f1f1;
    border-radius: 100px;
    margin: 40px auto 40px;
    padding: 0 20px;
    .search-input {
      background: #f1f1f1;
      flex: 1;
      margin-left: 20px;
      caret-color: #05b586;
    }
    .search-bar-btn {
      width: 48px;
      height: 48px;
      background: #05b586;
      border-radius: 50%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
/* 移动端导航 */
.drawer-mobile {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: 11;
  .drawer-top {
    width: 100vw;
    opacity: 1;
    position: relative;
    z-index: 14;
    height: 136rpx;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40rpx 0 28rpx;
    img {
      height: 50rpx;
      width: 50rpx;
    }
    span {
      width: 542rpx;
      text-align: center;
      font-size: 28rpx;
      font-weight: bold;
    }
    .close {
      height: 24rpx;
      width: 24rpx;
    }
  }
  .drawer-list {
    background: white;
    .drawer-list-i {
      padding: 30rpx 34rpx 30rpx 40rpx;
      font-size: 28rpx;
      // font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i {
        width: 40rpx;
        // font-weight: 600;
        height: 40rpx;
      }
    }
  }
  .drawer-footer {
    padding: 40rpx;
    width: 750rpx;
    height: 200rpx;
    background: #eff0f2;
    .icons {
      display: flex;

      img {
        width: 40rpx;
        height: 40rpx;
        margin-right: 24rpx;
      }
    }
    .lang {
      margin-top: 40rpx;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 24rpx;
      img {
        width: 40rpx;
        height: 40rpx;
      }
      span {
        margin: 0 20rpx;
      }
    }
  }
}

/* 图标 */
.menu-wrapper {
  position: absolute;
  top: calc((100% - 82rpx) / 2);
  // transform: translateY(-50%);
  right: 40rpx;
  width: 40rpx;
  height: 40rpx;
  cursor: pointer;
  z-index: 11;
  display: none;
}
.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 32rpx;
  height: 5rpx;
}
.hamburger-menu {
  position: relative;
  transform: translateY(40rpx);
  background: #000;
  transition: all 0ms 300ms;
}
.hamburger-menu.animate {
  background: rgba(255, 255, 255, 0);
}
.hamburger-menu:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 12rpx;
  background: #000;
  transition:
    bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: 12rpx;
  background: #000;
  transition:
    top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition:
    top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition:
    bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

/* 移动端兼容 */
@media screen and (max-width: 768px) {
  .header {
    min-height: 136rpx;
  }
  .link {
    display: none;
  }
  .navbar {
    position: fixed;
    top: 0;
    z-index: 13;
    width: 100vw;
    height: 136rpx;
    padding: 0 40rpx;

    .navbar-logo {
      width: 260rpx;
      height: 40rpx;
    }
    .navbar-box {
      display: none;
    }
    .navbar-search {
      padding-right: 78rpx;
      span {
        display: none;
      }
      .navbar-search-icon {
        display: block;
        .icon {
          height: 40rpx;
          width: 40rpx;
          margin-left: 40px;
        }
      }

      .menu-wrapper {
        display: block;
      }
    }
  }
  .drawer-mobile {
    display: block;
  }
  .drawer-nav {
    position: fixed;
    width: 750rpx;
    top: 136rpx;

    .close {
      right: 32rpx;
      top: 32rpx;
    }
    .drawer-search {
      margin-top: 110rpx;
      margin-bottom: 60rpx;
      width: 670rpx;
      height: 96rpx;
      padding: 0 16rpx;

      .search-bar-btn {
        width: 72rpx;
        height: 72rpx;
        img {
          width: 30rpx;
          height: 30rpx;
        }
      }
    }
  }
}
</style>
